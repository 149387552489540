if(window.location.href.indexOf("game") > -1) {
  window.onscroll = function () {
    if (window.screen.width >= 1024) {
      var mydivpos = document.getElementById("fix-break").offsetTop;
      var mydivposTwo = document.getElementById("fix-break-two").offsetTop;
      var scrollPos = document.documentElement.scrollTop;
      if(scrollPos >= mydivpos) {
        document.getElementById("fixedOne").style.display = "block";
      } else {
        document.getElementById("fixedOne").style.display = "none";
      }
    }
  };
  if (window.innerWidth < 641) {
    var gameContent = document.getElementById('game-content');
    var gameVideo = document.getElementById('game-video');

    var parent = gameContent.parentNode;

    parent.insertBefore(gameVideo, gameContent);

    var gameContentTwo = document.getElementById('game-content-two');
    var gameVideoTwo = document.getElementById('game-video-two');

    var parentTwo = gameContentTwo.parentNode;

    parentTwo.insertBefore(gameVideoTwo, gameContentTwo);
  }
  var play = document.getElementById('mobilePlay');
  var vid = document.getElementById("game-video-two");
  play.addEventListener("click", function() {
   play.style.display = 'none';
   vid.play();
  });
  vid.addEventListener("click", function() {
    play.style.display = 'none';
    vid.play();
  });
  var playTwo = document.getElementById('mobilePlayTwo');
  var vidTwo = document.getElementById("game-video");
  playTwo.addEventListener("click", function() {
   playTwo.style.display = 'none';
   vidTwo.play();
  });
  vidTwo.addEventListener("click", function() {
    playTwo.style.display = 'none';
    vidTwo.play();
  });

}

