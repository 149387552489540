let factionSlider = document.querySelectorAll(".js-faction-comparison");
  if (factionSlider.length) {

    let active = false;
    let scrollerTop = document.querySelector('.scroller-top');

    scrollerTop.addEventListener('mousedown',function(){
      active = "top";
      scrollerTop.classList.add('scrolling');
    });
    document.body.addEventListener('mouseup',function(){
      active = false;
      scrollerTop.classList.remove('scrolling');
    });
    document.body.addEventListener('mouseleave',function(){
      active = false;
      scrollerTop.classList.remove('scrolling');
    });

    document.body.addEventListener('mousemove',function(e){
      if (!active) return;
      let x = e.pageX;
      x -= document.querySelector('.wrapper').getBoundingClientRect().left;
      scrollIt(x);
    });

    function scrollIt(x) {
      let transform = Math.max(0,(Math.min(x,document.querySelector('.wrapper').offsetWidth)));
      if (active === "top") {
        document.querySelector('.top').style.width = transform+"px";
        scrollerTop.style.left = transform-25+"px";
      }
    }

    let scollerBoxWidth = document.querySelector('.faction-comparison').getBoundingClientRect().width / 2;
    let panelWidth = document.querySelector('.faction-comparison').getBoundingClientRect().width;
    let leftPanel = document.querySelector('.faction-comparison__left');
    let leftPanelRow = leftPanel.querySelector('.faction-comparison__content-row');
    leftPanelRow.style.width = panelWidth + 'px';

    let imgPanel = document.querySelector('.faction-comparison__right');
    let panelImg = imgPanel.getElementsByTagName('img')[0];
    panelImg.style.width = panelWidth + 'px';

    let imgPanelLeft = document.querySelector('.faction-comparison__left');
    let panelImgLeft = imgPanelLeft.getElementsByTagName('img')[0];
    panelImgLeft.style.width = panelWidth + 'px';

    active = "top";
    scrollIt(scollerBoxWidth);
    active = false;

    scrollerTop.addEventListener('touchstart',function(){
      active = "top";
      scrollerTop.classList.add('scrolling');
    });
    document.body.addEventListener('touchend',function(){
      active = false;
      scrollerTop.classList.remove('scrolling');
    });
    document.body.addEventListener('touchcancel',function(){
      active = false;
      scrollerTop.classList.remove('scrolling');
    });

    document.querySelector('.wrapper').addEventListener('touchmove',function(e){
      if (!active) return;
      e.preventDefault();
      let x = e.touches[0].pageX;
      x -= document.querySelector('.wrapper').getBoundingClientRect().left;
      scrollIt(x);
    });

};

