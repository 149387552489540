(function() {
  let Tabber = {
    get openTab() {
      return this._openTab;
    },
    set openTab(tabIndex) {
      if (this._openTab !== -1) {
        this.deactivateTab(this._openTab);
      }
      this._openTab = tabIndex;
      if (this._openTab !== -1) {
        this.activateTab(this._openTab);
      }
    },

    _desktopTabs: [],
    _tabItems: [],
    _openTab: -1,
    _tabTemplate: document.createElement("button"),


    init: function(element) {
      this._tabTemplate.type = "button";
      this._tabTemplate.classList.add("tabber__tabs-tab");
      this._tabItems = element.querySelectorAll("[data-tabber-part=item]");
      this._desktopTabContainer = element.querySelector("[data-tabber-part=tabs]");
      this._tabItems.forEach((item, index) => {
        this.itemEvents(item, index);
        this.setAccordionHeight(item);
      });
      if (this._desktopTabContainer) {
        this.createDesktopTabs();
        this._desktopTabs = element.querySelectorAll("[data-tabber-part=tabs] button");
      }

      this.openTab = 0;
    },

    itemEvents: function(item, index) {
      item.addEventListener("click", event => {
        if (event.target.nodeName === "BUTTON" || event.target.nodeName === "IMG" ) {
          this.toggleItem(index);
        }
      });
    },

    toggleItem: function(index) {
      if (this.openTab === index) {
        this.openTab = -1;
      } else {
        this.openTab = index;
      }
    },

    activateTab: function(index) {
      let tabToActivate = this._tabItems[index];
      let tabToggle = this._desktopTabs[index];
      let display = tabToActivate.querySelector("[data-tabber-part=display]");
      let height = display.dataset.tabberHeight;
      window.requestAnimationFrame(() => {
        display.style.height = height;
        tabToActivate.classList.add("--active");
        tabToggle.classList.add("--active");
      });
    },

    deactivateTab: function(index) {
      let tabToActivate = this._tabItems[index];
      let tabToggle = this._desktopTabs[index];
      let display = tabToActivate.querySelector("[data-tabber-part=display]");
      window.requestAnimationFrame(() => {
        display.style.height = "";
        tabToActivate.classList.remove("--active");
        tabToggle.classList.remove("--active");
      });
    },

    setAccordionHeight: function(item) {
      let displayPanel = item.querySelector("[data-tabber-part=display]");
      if (displayPanel) {
        let contentPanel = item.querySelector("[data-tabber-part=content]");
        if (contentPanel) {
          let styles = window.getComputedStyle(contentPanel);
          let margins =
            parseFloat(styles.getPropertyValue("margin-top")) + parseFloat(styles.getPropertyValue("margin-bottom"));

          let height = contentPanel.offsetHeight + margins + "px";
          displayPanel.dataset.tabberHeight = height;
        }
      }
    },

    createDesktopTabs: function() {
      this._tabItems.forEach((item, index) => {
        let tabToggle = item.querySelector("[data-tabber-part=toggle]");
        let newTab = this._tabTemplate.cloneNode(true);
        newTab.innerHTML = tabToggle.innerHTML;
        this.itemEvents(this._desktopTabContainer.appendChild(newTab), index);
      });
    }
  };
  let tabbers = document.querySelectorAll(".tabber");
  tabbers.forEach(item => {
    let temTab = Object.create(Tabber);
    temTab.init(item);
  });
})();
