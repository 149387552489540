if(window.location.href.indexOf("events") > -1) {
  var moment = require('../lib/moment.min.js');

  function init() {
    var today = moment();

    var nextDate = moment();
    nextDate.add(1, 'months');

    var startOfMonth = today.format('YYYY-MM-01');
    var endOfMonth = today.clone().endOf('month').format('YYYY-MM-DD');

    var nextMonthStart = nextDate.format('YYYY-MM-01');
    var nextMonthEnd = nextDate.clone().endOf('month').format('YYYY-MM-DD');

    var thisMonth = document.getElementById("thisMonth");
    if (thisMonth) {
      thisMonth.value = startOfMonth + " to " + endOfMonth;
    }

    var nextMonth = document.getElementById("nextMonth");
    if (nextMonth) {
      nextMonth.value = nextMonthStart + " to " + nextMonthEnd;
    }

    var allMonths = document.getElementById("allMonths");
    if (allMonths) {
      allMonths.value = "all";
    }
  }

  function eventFilter(e) {
    events = document.querySelectorAll('.js-event-filter');

    // get type and region filter
    var selectedType = document.getElementById("select-type").value || "all";
    var selectedRegion = "all";
    var regionSelector = document.getElementById("select-region");
    if (regionSelector) {
      selectedRegion = regionSelector.value || "all";
    }
    // get date range filter
    var theDateRangeString = "all";
    if (document.getElementById("customDate").value) {
      theDateRangeString = document.getElementById("customDate").value;
    } else {
      var activeDate = document.getElementById("upcoming-events__date-filter")
                               .getElementsByClassName("active")[0];
      // TODO(frank): make this less defensive
      if (activeDate) {
        theDateRangeString = activeDate.value;
      }
    }
    var theDateStrings = null;
    var startDate = null;
    var endDate = null;
    if (theDateRangeString != "all") {
      theDateStrings = theDateRangeString.split(" to ");
      startDate = moment(theDateStrings[0] + ' 00:00:00');
      endDate = moment(theDateStrings[1] + ' 23:59:59');
    }

    // use "today" to filter events which are already past
    var today = moment();

    for (var i = 0; i < events.length; i++) {
      var theType = events[i].dataset.eventtype;
      var theRegion = events[i].dataset.region;
      var theDate = moment(events[i].dataset.eventdate + ' 23:59:00');

      if ((selectedType == "all" || selectedType == theType) && 
          (selectedRegion == "all" || selectedRegion == theRegion) &&
          (theDateRangeString == "all" || ((theDate >= startDate) && (theDate <= endDate))) &&
          (today <= theDate)
         ) {
        events[i].style.display = "block";
      } else {
        events[i].style.display = "none";
      }
    }
  }

  // filter event by type selected
  document.querySelector("#select-type").onchange = eventFilter;
  // filter event by region selected
  var regionSelector = document.querySelector("#select-region");
  if (regionSelector) {
    regionSelector.onchange = eventFilter;
  }

  // filter events by month
  function updateDates(event) {
    var button = event.srcElement;
    document.getElementById('customDate').value = button.getAttribute('value');
    // re-enable highlight in case custom was used :/
    Array.prototype.forEach.call(document.querySelectorAll('.eventMonthLabel'), function(button) {
      button.classList.remove('active');
    });
    button.classList.add('active');

    eventFilter(event);
  }

  var buttons = document.querySelectorAll('.eventMonthLabel');
  Array.prototype.forEach.call(buttons, function(button) {
    button.addEventListener('click', updateDates);
  });

  // filter events by custom date
  function updateCustomDates(selectedDates) {
    // remove highlight from other month labels
    Array.prototype.forEach.call(document.querySelectorAll('.eventMonthLabel'), function(button) {
      button.classList.remove('active');
    });
    // TODO(frank): add active class to custom date range
    eventFilter(null);
    document.getElementById('customDateLabel').classList.add('active');
  }
  // window.stuff??? comes from the now-deleted date-picker.js, not my fault ;)
  window.stuff = flatpickr("#customDate", {
    mode: "range",
    dateFormat: "Y-m-d",
    onClose: updateCustomDates
  });

  init();
  eventFilter(null);
}
