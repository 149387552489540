let ScrollController = {
  scrollEvents: [],
  scrollDirection: 0,
  lastScrollPositions: [0, 0, 0, 0, 0],
  lastAverage: 0,
  scrollPosition: 0,

  init: function() {
    this.lastScrollPositions.fill(window.pageYOffset, 0, 4);
    window.addEventListener("scroll", () => {
      window.requestAnimationFrame(() => {
        this.updateScrollPositions();
        this.callEvents();
        this.calculateScrollDirection();
      });
    });
  },

  addCallback: function(callbackFunction) {
    this.scrollEvents.push(callbackFunction);
  },

  removeCallback: function(functionToRemove) {
    for (let i = 0; i < this.scrollEvents.length; i++) {
      if (this.scrollEvents[i] === functionToRemove) {
        this.scrollEvents.splice(i, 1);
      }
    }
  },

  callEvents: function() {
    for (let i = 0; i < this.scrollEvents.length; i++) {
      this.scrollEvents[i].call(this);
    }
  },

  updateScrollPositions: function() {
    this.scrollPosition = window.pageYOffset;
    this.lastScrollPositions.shift();
    this.lastScrollPositions.push(this.scrollPosition);
  },

  calculateScrollDirection: function() {
    let scrollValue = this.lastScrollPositions.reduce((sum, current) => {
      return sum + current;
    }, 0);
    scrollValue = scrollValue / 5;
    let diff = this.lastAverage - scrollValue;
    this.lastAverage = scrollValue;
    if (diff > 0) {
      this.scrollDirection = 1;
    } else if (diff < 0) {
      this.scrollDirection = -1;
    }
  },

  publicFunctions: function() {}
};
module.exports = ScrollController;
