let Menu = {
  _menuOpenElement: false,
  _menuCloseElement: false,
  _menuNavElement: false,
  _menuSearchElement: false,
  _menuContainerElement: false,
  _menuOpenTest: false,

  init: function() {
    this.setElements();
    this.addClickEvents();
    this.urlBase = window.location.origin;
  },

  setElements: function() {
    this._menuOpenElement = document.querySelector(".js-menu-open");
    this._menuCloseElement = document.querySelector(".js-menu-close");
    this._menuOpenTest = document.querySelector(".js-menu-open-test");
    this._menuNavElement = document.querySelector(".js-menu-nav");
    this._menuContainerElement = document.querySelector(".js-menu-container");
    this._docBodyElement = document.querySelector("body");
    this._topToggle = document.querySelectorAll(".js-top-toggle");
  },

  addClickEvents: function() {
    if (this._menuOpenElement) {
      this._menuOpenElement.addEventListener("click", this.openMenu);
    }
    if (this._menuOpenTest) {
      this._menuOpenTest.addEventListener("click", this.openMenuTest);
    }
    if (this._menuCloseElement) {
      this._menuCloseElement.addEventListener("click", this.closeMenu);
    }
    if (this._menuNavElement) {
      this._menuNavElement.addEventListener("click", event => {
        this.checkSubClick(event);
      });
    }

    document.addEventListener("click", function(event) {
    if (event.target.closest(".js-menu-nav")) return;
      for (var topToggle of Menu._topToggle) {
        topToggle.parentElement.classList.remove("--open-menu");
      }
    });

  },

  checkSubClick: function(event) {
    if (event.target.nodeName === "BUTTON") {
      this.toggleSubNav(event.target);
    }
  },

  openMenu: function() {
    Menu._menuNavElement.classList.add("--open");
    Menu._menuContainerElement.classList.add("--open");
    Menu.bodyOpen();
  },

  openMenuTest: function() {
    if (Menu._menuOpenTest.classList.contains("--open")) {
      Menu._menuOpenTest.classList.remove("--open");
      Menu._menuNavElement.classList.remove("--open");
      Menu.bodyClose();
    } else {
      Menu._menuOpenTest.classList.add("--open");
      Menu._menuNavElement.classList.add("--open");
      Menu.bodyOpen();
    }
  },

  closeMenu: function() {
    Menu._menuNavElement.classList.remove("--open");
    Menu._menuContainerElement.classList.remove("--open");
    Menu.bodyClose();
  },

  closeSubNavs: function(element) {
    element.parentElement.classList.toggle("--open-menu");
  },

  toggleSubNav: function(element) {
    element.parentElement.classList.toggle("--open-menu");
  },

  toggleBodyOpen: function() {
    if (Menu._docBodyElement.classList.contains("--open")) {
      Menu._docBodyElement.classList.remove("--open");
    } else {
      Menu._docBodyElement.classList.add("--open");
    }
  },

  bodyOpen: function() {
    Menu._docBodyElement.classList.add("--open");
  },

  bodyClose: function() {
    Menu._docBodyElement.classList.remove("--open");
  },
};

Menu.init();
