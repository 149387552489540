if(window.location.href.indexOf("events") > -1) {
  var $ = require("../lib/jquery-3.3.1.min.js");
  var partialData = $('#upcoming-events-metadata').data();

  function setLoading(showLoading) {
    if (showLoading) {
      $('#loadingLocation').removeClass('hide-loading');
    }
    else {
      $('#loadingLocation').addClass('hide-loading');
    }
  }

  function sortResults(latitude, longitude) {
    $('#sortByDate').removeClass("active");
    $('#sortByDistance').addClass("active");
    setLoading(false);

    var latlon = new LatLon(latitude, longitude);

    var event_holder = $(".upcoming-events__events");
    var events = event_holder.children("div");

    // add or update the distance data attribute
    events.each(function(index, event) {
      var e = $(event);
      var loc  = e.attr('data-latlon').split(',');
      e.attr('data-distance', latlon.distanceTo(new LatLon(Number(loc[0]),Number(loc[1]))));
    });

    // sort by distance attribute
    events.detach().sort(function(a, b) {
      return(Number($(a).attr('data-distance')) < Number($(b).attr('data-distance')) ? -1 : 1);
    });
    event_holder.append(events);
  }

  function sortResultsByPosition(position) {
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;
    $('#sortByDistanceFrom').val(latitude + ", " + longitude);
    sortResults(latitude, longitude);
  }

  function noPosition(error) {
    $('#sortByDistanceFrom').val("Unknown location");
    setLoading(false);
  }

  function sortByDate() {
    $('#sortByDate').addClass("active");
    $('#sortByDistance').removeClass("active");

    var event_holder = $(".upcoming-events__events");
    var events = event_holder.children("div");

    // sort by date attribute
    events.detach().sort(function(a, b) {
      return($(a).attr('data-eventdate') < $(b).attr('data-eventdate') ? -1 : 1);
    });
    event_holder.append(events);
  }

  function updateLocation(event) {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);
    $('#sortByDate').removeClass("active");
    $('#sortByDistance').addClass("active");

    if (!$("#sortByDistanceFrom").val() || $("#sortByDistanceFrom").val() === "Unknown location") {
      $("#sortByDistanceFrom").val('San Francisco, CA');
    }
    var address = $("#sortByDistanceFrom").val();
    var geocoder = new google.maps.Geocoder();

    geocoder.geocode({'address': address}, function(results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        var latitude = results[0].geometry.location.lat();
        var longitude = results[0].geometry.location.lng();
        $('#sortByDistanceFrom').val(results[0].formatted_address);
        sortResults(latitude, longitude);
      } else {
        setLoading(false);
      }
    });
  }
  setLoading(false);

  $('#sortByDistanceFrom').keypress(function(e) {
    // Enter pressed?
    if(e.which == 10 || e.which == 13) {
      updateLocation(e);
    }
    // TODO: last keypress more than 2 seconds ago?
  });
  $('#sortByDistance').click(function() {
    updateLocation(null);
  });
  $('#sortByDate').click(function() {
    setLoading(false);
    $('#sortByDistanceFrom').val("");
    sortByDate();
  });

  console.log(partialData);
  console.log(partialData.defaultsort);

  if (partialData.defaultsort != 'date') {
    $('#sortByDistanceFrom').val("Loading your location...");
    setLoading(true);
    $('#sortByDate').removeClass("active");
    $('#sortByDistance').addClass("active");
    navigator.geolocation.getCurrentPosition(sortResultsByPosition, noPosition);
  }

  if (partialData.defaulttype) {
    $('#select-type').val(partialData.defaulttype);
    $('#select-type').trigger('change');
  }
}
