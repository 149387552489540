(function() {
  let loadMoreButton = document.querySelectorAll(".js-load-more");
  if (loadMoreButton.length) {

  const loadMoreButton = document.querySelector('.js-load-more');
  const loadMoreContainer = document.querySelector('.js-load-more-container');

  loadMoreButton.addEventListener('click', () => {
    loadMoreContainer.classList.add('--active');
    loadMoreButton.classList.add('--not-active');
  });
  }
})();
