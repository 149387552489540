var WeekCount = document.getElementsByClassName("filter__slider").length

if (WeekCount >= 6) {
  document.getElementById("customize-controls").style.display = "block";
  (function() {
    let weekBlock = document.querySelectorAll(".filter__slider");
    if (weekBlock.length) {
      weekBlock.forEach(function(element, index) {
        let weekBlockSlider = tns({
          "container": ".filter__slider__container",
          "items": 3,
          "controls": true,
          "controlsContainer": "#customize-controls",
          "mode": "carousel",
          "loop": false,
          "nav": false,
          "mouseDrag": true,
          "edgePadding": 14,
          "disable": false,
          "swipeAngle": false,
          "responsive": {
            "1180": {
              "items": 4
            },
            "1400": {
              "items": 5
            },
            "1620": {
              "items": 7
            }
          }
        });
      });
    }
  })();
}
