(function() {
  let weekBlock = document.querySelectorAll(".week-block");
  if (weekBlock.length) {
    weekBlock.forEach(function(element, index) {
      let weekBlockSlider = tns({
        "container": '.js-week-block',
        "items": 1,
        "mode": 'carousel',
        "loop": false,
        "mouseDrag": true,
        "fixedWidth": 200,
        "nav": true,
        "navContainer": '#intel-slider',
        "navAsThumbnails": false,
        "gutter": 20,
        "controls": false,
        "disable": false,
        "swipeAngle": false,
        "responsive": {
          "1025": {
            "mode": "carousel",
            "loop": false,
            "disable": true,
            "nav": false,
          }
        }
      });
    });
  }
})();
