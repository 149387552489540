require("./components/background-image");
require("./components/menu");
require("./components/news-slider");
require("./components/featured-events-slider");
require("./components/exploratory-image-scene");
require("./components/video");
require("./components/video-overlay");
require("./components/tabber");
require("./components/download-modal");
require("./components/load-more");
require("./components/signup-form");
require("./components/cookie-notice");
require("./components/faction-comparison-slider");
require("./components/fixed-content");
require("./components/event-date-filter");
require("./components/event-geocoder-filter");
require("./investigator-board/week-slider");
require("./investigator-board/week-slider-bar");
require("./investigator-board/filters");
window.scroller = require("./components/scroller.js");
window.scroller.init();

var siteHeader = document.querySelector(".js-site-header");
var headerScroll = function() {
  var header = siteHeader;
  var headerWidth = header.offsetWidth;
  if (headerWidth > 1200) {
    if (this.scrollPosition > 200) {
      header.classList.add("--scrolled");
    } else {
      header.classList.remove("--scrolled");
    }
  } else {
    if (this.scrollPosition > 100) {
      header.classList.add("--scrolled");
    } else {
      header.classList.remove("--scrolled");
    }
  }
};

window.scroller.addCallback(headerScroll);
