let downloadOverlayTrigger = document.querySelector('.js-download-modal-trigger');
let downloadOverlay = document.querySelector('.js-download-modal');
let downloadOverlayClose = document.querySelector('.js-download-modal-close');

downloadOverlayTrigger.addEventListener('click', openDownloadOverlay);
downloadOverlayClose.addEventListener('click', closeDownloadOverlay);

function openDownloadOverlay() {
  downloadOverlay.classList.add('--active');
  window.location.hash = '#download';
};

function closeDownloadOverlay() {
  downloadOverlay.classList.remove('--active');
  window.location.hash = '';
};

window.addEventListener('load', function() {
  if (window.location.hash == '#download') {
    openDownloadOverlay();
  }
});
