(function() {
  let featuredEvents = document.querySelectorAll(".featured-events__events");
  if (featuredEvents.length) {
    featuredEvents.forEach(function(element, index) {
    let featuredEventsSlider = tns({
      "container": '.js-featured-events-block',
      "items": 1,
      "mouseDrag": true,
      "edgePadding": 40,
      "gutter": 20,
      "controls": false,
      "responsive": {
        "600": {
          "items": 2,
        },
        "1120": {
          "items": 3,
          "edgePadding": 0,
        }
      }
    });
    });
  }
})();
