(function() {
  let backgroundImages = document.querySelectorAll(".js-background-image");
  if (backgroundImages) {
    backgroundImages.forEach(function(element, index) {
      let imageElement = element.querySelector("img");
      let backgroundImageSrc = "";
      if (imageElement) {
        backgroundImageSrc = imageElement.currentSrc || imageElement.src;
        element.style.backgroundImage = "url(" + backgroundImageSrc + ")";
        imageElement.addEventListener("load", event => {
          backgroundImageSrc = imageElement.currentSrc || imageElement.src;
          element.style.backgroundImage = "url(" + backgroundImageSrc + ")";
        });
      }
    });
  }

  window.addBackground = function(element) {
    if (element) {
      let imageElement = element.querySelector("img");
      let backgroundImageSrc = "";
      if (imageElement) {
        backgroundImageSrc = imageElement.currentSrc || imageElement.src;
        element.style.backgroundImage = "url(" + backgroundImageSrc + ")";
        imageElement.addEventListener("load", event => {
          backgroundImageSrc = imageElement.currentSrc || imageElement.src;
          element.style.backgroundImage = "url(" + backgroundImageSrc + ")";
        });
      }
    }
  };
})();
