// This will eventually live in a partial.
let ExploratoryOverlay = {
  init: function() {
    this.setCharacters();
  },
  setCharacters: function() {
    this._characters = document.querySelectorAll(".js-exploratory-image-scene-character");
    for (var index = 0; index < this._characters.length; index++) {
      this._characters[index].addEventListener('click', function(event) {
        let overlay = event.currentTarget.querySelector(".js-exploratory-image-scene-overlay");
        let close = event.currentTarget.querySelector(".js-exploratory-image-scene-close-overlay");
        event.currentTarget.classList.add("--open");
        overlay.classList.add("--open");
        close.addEventListener("click", (event)=>{
          event.stopPropagation();
          overlay.classList.remove("--open");
        });
      });
    }
  },
};

ExploratoryOverlay.init();
