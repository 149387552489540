(function() {
  let newsBlock = document.querySelectorAll(".news-block");
  if (newsBlock.length) {
    newsBlock.forEach(function(element, index) {
    let newsBlockSlider = tns({
      "container": '.js-news-block',
      "items": 1,
      "mouseDrag": true,
      "gutter": 20,
      "edgePadding": 40,
      "controls": false,
      "responsive": {
        "412": {
          "items": 2,
          "edgePadding": 0,
        },
        "720": {
          "items": 3,
          "edgePadding": 0,
        },
        "1120": {
          "items": 4,
          "edgePadding": 0,
        }
      }
    });
    });
  }
})();
