var tagFilter = {
  weekFilterElements: false,
  intelBlockElements: false,
  noteFilterElements: false,

  init: function() {
    this.arcFilterElements = document.querySelectorAll(".js-filter-arc-toggle");
    this.arcFilterTrigger = document.querySelectorAll(".js-arc-filter");
    this.weekFilterElements = document.querySelectorAll(".js-filter-week-toggle");
    this.noteFilterElements = document.querySelectorAll(".week-notes-js");
    this.noteIntelFilterElements = document.querySelectorAll(".week-notes-intel-js");
    this.weekFilterTitle = document.querySelectorAll(".filter__date");
    this.intelBlockElements = document.querySelectorAll(".js-intel-block");
    this.intelTagElements = document.querySelectorAll(".js-intel-tag");

    this.currentWeek = '';
    this.currentArc = '';

    var intelMetaTag = document.getElementById("js-intel-current-values");
    if (intelMetaTag) {
      this.currentWeek = intelMetaTag.getAttribute("data-week");
      this.currentArc = intelMetaTag.getAttribute("data-arc");
    }

    this.checkWeek();

    var weekHash = window.location.hash || '';
    if (weekHash.length) {
      this.openWeek(weekHash.substring(1));
    }
  },

  getWeek: function(weeks) {
    var theWeek = weeks.dataset.week;
    var theArc = weeks.dataset.arc;
    openWeek(theWeek);
  },


  checkWeek: function(theWeek) {
    for (var i = 0; i < this.intelBlockElements.length; i++) {
      if (this.intelBlockElements[i].dataset.week != this.currentWeek) {
        this.intelBlockElements[i].style.display = "none";
      } else {
        this.intelBlockElements[i].style.opacity = 1;
        this.intelBlockElements[i].style.display = "block";
      }
    }
    for (var i = 0; i < this.noteFilterElements.length; i++) {
      if (this.noteFilterElements[i].dataset.note != this.currentWeek) {
        this.noteFilterElements[i].style.display = "none";
      } else {
        this.noteFilterElements[i].style.display = "block";
      }
    }
    for (var i = 0; i < this.noteIntelFilterElements.length; i++) {
      if (this.noteIntelFilterElements[i].dataset.note != this.currentWeek) {
        this.noteIntelFilterElements[i].style.display = "none";
      } else {
        this.noteIntelFilterElements[i].style.display = "block";
      }
    }
    for (var i = 0; i < this.arcFilterElements.length; i++) {
      if (this.arcFilterElements[i].dataset.arc != this.currentArc) {
        this.arcFilterElements[i].style.display = "none";
      } else {
        this.arcFilterElements[i].style.display = "block";
      }
    }
    if (this.currentWeek) {
      this.setHTMLWeekTitles(this.currentWeek);
    }

    this.addEvents();
  },

  openWeek: function(theWeek) {
    window.location.hash = theWeek;

    var archiveOverlayEl = document.getElementById("archive__overlay");
    if (archiveOverlayEl) {
      archiveOverlayEl.style.visibility = "hidden";
    }
    document.getElementById("filter-bar-tag").style.display = "none";
    document.getElementById("filter-bar").style.display = "block";

    if (screen.width <= "1025px") {
      document.getElementById("intel-slider").style.display = "block";
    }
    for (var i = 0; i < this.noteFilterElements.length; i++) {
      if (this.noteFilterElements[i].dataset.note != theWeek) {
        this.noteFilterElements[i].style.display = "none";
      } else {
        this.noteFilterElements[i].style.display = "block";
      }
    }
    for (var i = 0; i < this.noteFilterElements.length; i++) {
      if (this.noteIntelFilterElements[i].dataset.note != theWeek) {
        this.noteIntelFilterElements[i].style.display = "none";
      } else {
        this.noteIntelFilterElements[i].style.display = "block";
      }
    }
    for (var i = 0; i < this.intelBlockElements.length; i++) {
      if (this.intelBlockElements[i].dataset.week != theWeek) {
        this.intelBlockElements[i].style.display = "none";
      } else {
        this.intelBlockElements[i].style.display = "block";
        this.setHTMLWeekTitles(theWeek);
      }
    }
  },
  openArc: function(theArc) {
    document.getElementById("archive__overlay").style.visibility = "hidden";
    for (var i = 0; i < this.arcFilterElements.length; i++) {
      if (this.arcFilterElements[i].dataset.arc != theArc) {
        this.arcFilterElements[i].style.display = "none";
      } else {
        this.arcFilterElements[i].style.display = "block";
      }
    }
     document.getElementById("js-arc-title").innerHTML = theArc;
     document.getElementById("js-bar-arc-title").innerHTML = theArc;

  },

  setHTMLWeekTitles: function(theWeek) {
    var shortWeek = theWeek.substr(0, 5);
    document.getElementById("js-week-title").innerHTML = "Week of " + theWeek;
    document.getElementById("js-week-title-tag").innerHTML = "Back to " + theWeek;
    document.getElementById("js-week-note-title").innerHTML = "WEEK OF " + theWeek;
    document.getElementById("js-week-note-title-short").innerHTML = shortWeek;
    document.getElementById("js-week-archive-title").innerHTML = theWeek;
  },

  toggleTag: function(currentTag) {
    document.getElementById("intel-slider").style.display = "none";
    document.getElementById("week-transform").style.transform = "none";
    for (var i = 0; i < this.intelBlockElements.length; i++) {
      if (!this.intelBlockElements[i].dataset.tags.includes(currentTag)) {
        this.intelBlockElements[i].style.opacity = 0;
        this.intelBlockElements[i].classList.add("--close");
      } else {
        this.intelBlockElements[i].style.opacity = 1;
        this.intelBlockElements[i].classList.remove("--close");
        document.getElementById("filter-bar-tag").style.display = "block";
        document.getElementById("filter-bar").style.display = "none";
        document.getElementById("js-tag-title").innerHTML = currentTag;
      }
    }
  },

  addEvents: function() {
    this.weekFilterElements.forEach(function(element) {
      element.addEventListener("click", function(event) {
        tagFilter.openWeek(element.dataset.week);
      });
    });
    this.intelTagElements.forEach(function(element) {
      element.addEventListener("click", function(event) {
        tagFilter.toggleTag(element.dataset.tagName);
      });
    });
     this.arcFilterTrigger.forEach(function(element) {
      element.addEventListener("click", function(event) {
        tagFilter.openArc(element.dataset.arc);
      });
    });
  }
};

tagFilter.init();

