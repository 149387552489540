let Video = {
  init: function() {
    Video.setVideos();
  },

  setVideos: function() {
    let heroVideoContainer = document.querySelectorAll(".js-hero-video-container");
    if (heroVideoContainer.length) {
      heroVideoContainer.forEach(function(element, index) {
        let videoContainer = element;
        let videoContainerWidth = videoContainer.offsetWidth;
        let video = videoContainer.getElementsByTagName("video")[0];

        let landingSrc =
          "<iframe allowFullScreen='allowFullScreen' src='https://www.youtube.com/embed/RJ52YyG4BJQ?ecver=1&amp;iv_load_policy=1&amp;rel=0&amp;yt:stretch=16:9&amp;autohide=1&amp;color=red&amp;width=560&amp;width=560' width='560' height='315' allowtransparency='true' frameborder='0'></iframe>";
        let gameOneSrc =
          "<iframe allowFullScreen='allowFullScreen' src='https://www.youtube.com/embed/_LXyYgMaUuE?ecver=1&amp;iv_load_policy=1&amp;rel=0&amp;yt:stretch=16:9&amp;autohide=1&amp;color=red&amp;width=560&amp;width=560' width='560' height='315' allowtransparency='true' frameborder='0'></iframe>";
        let gameTwoSrc =
          "<iframe allowFullScreen='allowFullScreen' src='https://www.youtube.com/embed/lN790amuld0?ecver=1&amp;iv_load_policy=1&amp;rel=0&amp;yt:stretch=16:9&amp;autohide=1&amp;color=red&amp;width=560&amp;width=560' width='560' height='315' allowtransparency='true' frameborder='0'></iframe>";
        let gameThreeSrc =
          "<iframe allowFullScreen='allowFullScreen' src='https://www.youtube.com/embed/rsxXB5Np6sY?ecver=1&amp;iv_load_policy=1&amp;rel=0&amp;yt:stretch=16:9&amp;autohide=1&amp;color=red&amp;width=560&amp;width=560' width='560' height='315' allowtransparency='true' frameborder='0'></iframe>";
        let gameFourSrc =
          "<iframe allowFullScreen='allowFullScreen' src='https://www.youtube.com/embed/v=sJRX8E-Yy3o?ecver=1&amp;iv_load_policy=1&amp;rel=0&amp;yt:stretch=16:9&amp;autohide=1&amp;color=red&amp;width=560&amp;width=560' width='560' height='315' allowtransparency='true' frameborder='0'></iframe>";

        let videoTriggers = document.querySelectorAll(".js-video-trigger");
        if (videoContainer.querySelector(".js-video-overlay-trigger")) {
          let trigger = document.querySelector(".js-video-overlay-trigger");
          trigger.addEventListener("click", function() {
            openVideoOverlay();
            document.querySelector(".hero__player").innerHTML = landingSrc;
          });
        }

        if (videoTriggers) {
          for (let i = 0; i < videoTriggers.length; i++) {
            videoTriggers[i].addEventListener("click", function(event) {
              event.preventDefault();
              let current = event.currentTarget;

              if (current.dataset["video"]) {
                openVideoOverlay();
                loadVideo(current.dataset["video"]);
              }
            });
          }
        }

        if (videoContainer.querySelector(".js-video-game-trigger")) {
          let gameOneTrigger = document.querySelector(".js-video-game-trigger");
          gameOneTrigger.addEventListener("click", function() {
            openVideoOverlay();
            z = document.getElementById("zindex");
            z.classList.add("--zindex");
            f = document.getElementById("fixed");
            f.style.opacity = "0";
            loadGameOneVideo();
          });
        }

        if (videoContainer.querySelector(".js-video-game-trigger-two")) {
          let gameTwoTrigger = document.querySelector(".js-video-game-trigger-two");
          gameTwoTrigger.addEventListener("click", function() {
            openVideoOverlay();
            z = document.getElementById("zindex");
            z.classList.add("--zindex");
            f = document.getElementById("fixed");
            f.style.opacity = "0";
            loadGameTwoVideo();
          });
        }

        if (videoContainer.querySelector(".js-video-game-trigger-three")) {
          let gameThreeTrigger = document.querySelector(".js-video-game-trigger-three");
          gameThreeTrigger.addEventListener("click", function() {
            openVideoOverlay();
            z = document.getElementById("zindex");
            z.classList.add("--zindex");
            f = document.getElementById("fixed");
            f.style.opacity = "0";
            loadGameThreeVideo();
          });
        }

        if (videoContainer.querySelector(".js-video-game-trigger-four")) {
          let gameFourTrigger = document.querySelector(".js-video-game-trigger-four");
          gameFourTrigger.addEventListener("click", function() {
            openVideoOverlay();
            z = document.getElementById("zindex");
            z.classList.add("--zindex");
            f = document.getElementById("fixed");
            f.style.opacity = "0";
            loadGameFourVideo();
          });
        }

        if (videoContainer.querySelector(".js-video-overlay-close")) {
          let videoOverlay = videoContainer.querySelector(".js-video-overlay");
          let videoOverlayTrigger = videoContainer.querySelector(".js-video-overlay-trigger");
          let videoOverlayClose = videoContainer.querySelector(".js-video-overlay-close");
          let heroVideoContainer = videoContainer.querySelector(".hero__player-container");
          videoOverlayClose.addEventListener("click", closeVideoOverlay);

          function openVideoOverlay() {
            videoOverlay.classList.add("--active");
            heroVideoContainer.style.display = "block";
          }

          function loadGameOneVideo() {
            document.querySelector(".hero__player").innerHTML = gameOneSrc;
          }

          function loadGameTwoVideo() {
            document.querySelector(".hero__player").innerHTML = gameTwoSrc;
          }

          function loadGameThreeVideo() {
            document.querySelector(".hero__player").innerHTML = gameThreerc;
          }

          function loadGameFourVideo() {
            document.querySelector(".hero__player").innerHTML = gameFourSrc;
          }

          function closeVideoOverlay() {
            videoOverlay.classList.remove("--active");
            heroVideoContainer.style.display = "none";
            z = document.getElementById("zindex");
            z.classList.remove("--zindex");
            f = document.getElementById("fixed");
            f.style.opacity = "1";
            document.querySelector(".hero__player").innerHTML = "";
          }
          function loadVideo(videoId) {
            videoHtml = `<iframe allowFullScreen='allowFullScreen' src='https://www.youtube.com/embed/${videoId}?ecver=1&amp;iv_load_policy=1&amp;rel=0&amp;yt:stretch=16:9&amp;autohide=1&amp;color=red&amp;width=560&amp;width=560' width='560' height='315' allowtransparency='true' frameborder='0'></iframe>`;
            document.querySelector(".hero__player").innerHTML = videoHtml;
          }
        }

        if (video) {
          let width = videoContainer.offsetWidth;
          let height = videoContainer.offsetHeight;

          if (width >= height) {
            let heightCheck = width * (9 / 16);
            if (heightCheck > height) {
              // setWidth
              let topOffset = (height - width * (9 / 16)) / 2 + "px";

              video.style.width = width + "px";
              video.style.height = width * (9 / 16) + "px";
              video.style.top = topOffset;
              video.style.left = "0px";
            } else {
              // setHeight
              let leftOffset = (width - height / (9 / 16)) / 2 + "px";
              video.style.width = height / (9 / 16) + "px";
              video.style.height = height + "px";
              video.style.top = "0px";
              video.style.left = leftOffset;
            }
          } else {
            let leftOffset = (width - height / (9 / 16)) / 2 + "px";
            video.style.width = height / (9 / 16) + "px";
            video.style.height = height + "px";
            video.style.top = "0px";
            video.style.left = leftOffset;
          }

          if (videoContainerWidth > 800) {
            video.play();
          } else {
            video.pause();
          }
        }
      });
    }
  },
};

Video.init();
