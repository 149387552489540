var VO = {
  get open() {
    return this._open;
  },
  set open(state) {
    this._open = state;
    if (state) {
      this.openOverlay();
    } else {
      this.closeOverlay();
    }
  },
  get currentVideo() {
    return this._currentVideo;
  },
  set currentVideo(newVideo) {
    this._currentVideo = newVideo;
    if (newVideo === "") {
      this.removeVideo();
      this.open = false;
    } else {
      this.setVideo();
      this.open = true;
    }
  },

  _open: false,
  _currentVideo: "",
  overlayElement: false,
  overlayCloseElement: false,

  init: function() {
    this.overlayElement = document.querySelector(".js-video-overlay-global");
    if (this.overlayElement) {
      this.overlayCloseElement = this.overlayElement.querySelector(".js-video-close");
      this.videoPlayerElement = this.overlayElement.querySelector(".js-video-player");
      this.addEvents();
    }
  },
  addEvents: function() {
    this.overlayCloseElement.addEventListener("click", () => {
      this.currentVideo = "";
    });
    var newsBlock = document.querySelectorAll(".js-video-game-trigger-four");
    if (newsBlock.length > 0) {
      for (var i = 0; i < newsBlock.length; i++) {
        newsBlock[i].addEventListener("click", () => {
          this.currentVideo = `<div class="hero__player-container"><iframe width="100%" height="715" src="https://www.youtube.com/embed/5AGR54FJlCY?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
        });
      }
    }
    var newsBlock = document.querySelectorAll(".js-video-game-trigger-five");
    if (newsBlock.length > 0) {
      for (var i = 0; i < newsBlock.length; i++) {
        newsBlock[i].addEventListener("click", () => {
          this.currentVideo = `<div class="hero__player-container"><iframe width="100%" height="715" src="https://www.youtube.com/embed/ZpOM76px0EA?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
        });
      }
    }
    var videoTriggers = document.querySelectorAll('.js-video-trigger');
    if (videoTriggers.length > 0) {
      for (var i = 0; i < videoTriggers.length; i++) {
        videoTriggers[i].addEventListener("click", (event) => {
          var video = event.currentTarget;
          videoPop = video.dataset["video"]
          document.getElementsByClassName("js-video-overlay-global").style.display = 'none';
          this.currentVideo = '<div class="hero__player-container"><iframe width="100%" height="715" src="https://www.youtube.com/embed/' + videoPop + '?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
          console.log(this.currentVideo);
        });
      }
    }
  },
  toggleOverlay: function() {},
  openOverlay: function() {
    this.overlayElement.classList.add("--open");
  },
  closeOverlay: function() {
    this.overlayElement.classList.remove("--open");
  },

  setVideo: function() {
    this.videoPlayerElement.innerHTML = this.currentVideo;
  },
  removeVideo: function() {
    this.videoPlayerElement.innerHTML = "";
  }
};

VO.init();
