const cookieNotice = document.querySelector('.js-cookie-notice');
const cookieNoticeClose = document.querySelector('.js-cookie-notice-close');

if (!localStorage.getItem('cookieNotice')) {
  cookieNotice.classList.add('--is-active');
}

cookieNoticeClose.addEventListener('click', () => {
  localStorage.setItem('cookieNotice', true);
  cookieNotice.classList.remove('--is-active');
});
